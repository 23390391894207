import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { NewPassInterface } from '../../../interfaces/new-pass.interface';
import { ApiService } from '../../../api.service';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng';

@Component({
  selector: 'app-pwd-change',
  templateUrl: './pwd-change.component.html',
  styleUrls: ['./pwd-change.component.scss'],
})
export class PwdChangeComponent implements OnInit {
  pendingRequest = false;
  pwdChangeForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    if (this.config.data && this.config.data.isManager) {
      this.pwdChangeForm = this.fb.group({
        newPass: ['', [Validators.required]],
        newPass2: ['', [Validators.required, RxwebValidators.compare({fieldName: 'newPass'})]],
      });
    } else {
      this.pwdChangeForm = this.fb.group({
        oldPass: ['', [Validators.required]],
        newPass: ['', [Validators.required, RxwebValidators.different({fieldName: 'oldPass'})]],
        newPass2: ['', [Validators.required, RxwebValidators.compare({fieldName: 'newPass'})]],
      });
    }
  }

  onSubmit(): void {
    this.pendingRequest = true;
    if (this.config.data && this.config.data.isManager) {
      const newPassDto: NewPassInterface = {
        id: this.config.data.id,
        newPass: this.pwdChangeForm.get('newPass').value
      };

      this.apiService.changeManagerPassword(newPassDto).pipe(take(1)).subscribe(
        (res) => {
          this.pendingRequest = false;
          this.ref.close();
        }, (e: HttpErrorResponse) => {
          this.pendingRequest = false;
          console.log(e);
        }
      );
    } else {
      const newPassDto: NewPassInterface = {
        oldPass: this.pwdChangeForm.get('oldPass').value,
        newPass: this.pwdChangeForm.get('newPass').value
      };

      this.apiService.changeAdminPassword(newPassDto).pipe(take(1)).subscribe(
        (res) => {
          this.pendingRequest = false;
          this.router.navigate(['/auth']);
          localStorage.removeItem('authToken');
        }, (err: HttpErrorResponse) => {
          this.pendingRequest = false;
          console.log(err);
        }
      )
    }
  }
}
