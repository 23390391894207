<ng-container *transloco="let t; read: 'pwdChange'">
  <div class="pwdChange">
    <app-header class="users__title" [title]="t('title')"></app-header>

    <div class="pwdChange__form">
      <form [formGroup]="pwdChangeForm" (ngSubmit)="onSubmit()">
        <div class="form-group p-grid p-dir-col">
          <app-input
            *ngIf="pwdChangeForm.controls.oldPass"
            [white]="false"
            [id]="'oldPass'"
            [type]="'password'"
            [control]="pwdChangeForm.controls.oldPass"
            [placeholder]="t('placeholder.oldPass')"
            [iconSrc]="'assets/icons/lock.svg'"
            formControlName="oldPass"
          ></app-input>

          <app-input
            [white]="false"
            [id]="'newPass'"
            [type]="'password'"
            [control]="pwdChangeForm.controls.newPass"
            [placeholder]="t('placeholder.newPass')"
            [iconSrc]="'assets/icons/lock.svg'"
            formControlName="newPass"
          ></app-input>

          <app-input
            [white]="false"
            [id]="'newPass2'"
            [type]="'password'"
            [control]="pwdChangeForm.controls.newPass2"
            [placeholder]="t('placeholder.newPass2')"
            [iconSrc]="'assets/icons/lock.svg'"
            formControlName="newPass2"
          ></app-input>

          <button pButton pRipple type="submit" label="{{ t('changePassword')}}"
                  class="p-button-rounded p-text-uppercase addN*ew p-mt-4 p-mb-2"
                  [disabled]="!pwdChangeForm.valid || pendingRequest">
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
